
#table-container {
  .ant-table-thead {
    .ant-table-cell {
      background: #495056 !important;
      color: #FFFFFF !important;
      font-weight: 700;
      font-size: 14px;
      padding: 18px !important;
      border-right: none !important;
      border-left: none !important;
      border-top: none !important;
    }
  }

  .ant-pagination-item-active {
    border: none !important;
    background: #495056 !important;
    color: #FFFFFF !important;
    font-size: 16px;
    font-weight: 700;
  }

  .ant-table-tbody .ant-table-row {
    cursor: pointer;
  }
}