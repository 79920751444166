.ant-popover-inner {
  background-color: #FFFFFF !important;
}

.ant-popover-arrow {
  display: none !important;
  --antd-arrow-background-color: #FFFFFF !important;
}

.ant-popover-content {
  margin-left: 10px;
  margin-right: 10px;
}